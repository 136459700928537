import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// import { getInitialLanguage } from './preserve'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      EN: {
        translations: {
          Телеграм: 'Telegram',
          Твиттер: 'Twitter',
          'Телеграм канал': 'Telegram channel',
          'Частный отряд космонавтов': 'United space team',
        },
      },
      /*
      RU: {
        translations: {
          Телеграм: 'Телеграм',
          'Телеграм канал': 'Телеграм канал',
          'Частный отряд космонавтов': 'Частный отряд космонавтов',
        },
      },*/
    },

    fallbackLng: 'EN',
    keySeparator: false, // we use content as keys
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

// i18next.changeLanguage(getInitialLanguage())

export default i18next
