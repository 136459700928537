import React, { FunctionComponent } from 'react'

import styles from './styles.module.scss'

export type StatementProps = {
  link: string
  caption: string
  author: string
}

const Statement: FunctionComponent<StatementProps> = ({
  link,
  caption,
  author,
}) => {
  return (
    <div className={styles.statement}>
      <span className={styles.hyphen}>—</span>{' '}
      <a href={link} target="_blank" rel="noreferrer">
        {caption}
      </a>
      <span className={styles.author}>
        {' —'} {author}
      </span>
    </div>
  )
}

export default Statement
