import React, { FunctionComponent } from 'react'
import styles from './styles.module.scss'
import { lavaCanvas } from 'modules/lava'

type LogoProps = {}

const favicon = document.getElementById('favicon') as HTMLLinkElement

const Logo: FunctionComponent<LogoProps> = () => {
  setInterval(() => {
    const logo = document.getElementById('logo') as HTMLImageElement
    if (!logo || !lavaCanvas) {
      return
    }
    const image = lavaCanvas.toDataURL()
    logo.src = image
    if (favicon) {
      favicon.href = image
    }
  }, 10)

  return (
    <div className={styles.logoWrapper}>
      <img className={styles.logo} id="logo" alt="Dr Pendex" />
    </div>
  )
}

export default Logo
