import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

type MenuProps = {
  onScrollToScience: () => void
}

const Menu: FunctionComponent<MenuProps> = ({ onScrollToScience }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.menu}>
      <a
        target="_blank"
        rel="noreferrer"
        className={styles.link}
        href="/files/DrPendex brief v1.pdf"
      >
        {t('About')}
      </a>
      <a
        className={styles.link}
        href="/"
        onClick={e => {
          e.preventDefault()
          onScrollToScience()
        }}
      >
        {t('Science behind')}
      </a>

      <a
        href="/"
        className={styles.link}
        onClick={e => {
          e.preventDefault()
          ;(window as any).indemandly.openWidget()
        }}
      >
        {t('Contact')}
      </a>
    </div>
  )
}

export default Menu
