import React, { ReactNode, FunctionComponent } from 'react'

import styles from './styles.module.scss'

type WrapperProps = {
  children: ReactNode
}

const Wrapper: FunctionComponent<WrapperProps> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>
}

export default Wrapper
