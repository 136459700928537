import React, { FunctionComponent, Fragment } from 'react'
// import { useRecoilState } from 'recoil'

import Wrapper from 'components/wrapper'
import Landing from 'components/landing'

const App: FunctionComponent = () => {
  return (
    <Fragment>
      <Wrapper>
        <Landing />
      </Wrapper>
    </Fragment>
  )
}

export default App
