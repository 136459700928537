import React, { FunctionComponent, useRef, useState } from 'react'
import classnames from 'classnames'

import Logo from 'atoms/logo'
import Menu from 'components/menu'
import Statement, { StatementProps } from 'atoms/statement'
import styles from './styles.module.scss'

const statements: Array<StatementProps> = [
  {
    link:
      'https://medium.com/oreillymedia/what-is-ai-first-healthcare-519d2aaa4db',
    caption: 'What Is AI-First Healthcare',
    author: "O'Reilly Media",
  },
  {
    link: '/files/How To Produce Super Humans For Mars Mission.pdf',
    caption: 'How To Produce Super Humans For Mars Mission?',
    author: 'DrPendex itself',
  },
  {
    link: '/files/AI for Outer Space Medicine.pdf',
    caption: 'AI for Outer Space Medicine',
    author: 'DrPendex itself',
  },

  {
    link: '/files/How To Heal Varicose Veins.pdf',
    caption: 'How To Heal Varicose Veins',
    author: 'DrPendex itself',
  },
  {
    link: '/files/Blank 2 How AI Is Changing Space Flight Logistics.pdf',
    caption: 'Blank 2 How AI Is Changing Space Flight Logistics',
    author: 'DrPendex itself',
  },
  {
    link: '/files/OMS is the game changer.pdf',
    caption: 'OMS is the game changer',
    author: 'DrPendex itself',
  },
  {
    link: '/files/New In Space Medicine.pdf',
    caption: 'New In Space Medicine',
    author: 'DrPendex itself',
  },
  {
    link: '/files/Future Of Communication In Space Flights Using Ai.pdf',
    caption: 'Future Of Communication In Space Flights Using Ai',
    author: 'DrPendex itself',
  },
  {
    link: '/files/Radiation Showstopper Is No More Here.pdf',
    caption: 'Radiation Showstopper Is No More Here',
    author: 'DrPendex itself',
  },
]

// Blank 2 How AI Is Changing Space Flight Logistics.pdf
type LandingProps = {}

const Landing: FunctionComponent<LandingProps> = () => {
  const myRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const scrollToScience = () => {
    if (myRef !== null && myRef.current) {
      setIsVisible(true)
      setTimeout(() => {
        // @ts-ignore
        // myRef.current.scrollIntoView()
      }, 1000)
    }
  }
  // run this function from an event handler or an effect to execute scroll

  return (
    <div className={styles.landing}>
      <div
        className={classnames(styles.menu, {
          [styles.removeMargin]: isVisible,
        })}
      >
        <Menu onScrollToScience={scrollToScience} />
      </div>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.subtitle}>You are not alone</div>
      <div
        ref={myRef}
        className={classnames(styles.science, { [styles.visible]: isVisible })}
      >
        {statements.map((statement, key) =>
          statement.caption ? <Statement {...statement} key={key} /> : false
        )}
      </div>
    </div>
  )
}

export default Landing
